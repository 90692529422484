
  import {
    defineComponent,
    onMounted,
    PropType,
    ref,
    nextTick,
    watch,
    onActivated,
    onDeactivated,
  } from "vue";
  import Swiper from "swiper";
  export interface BannerList {
    link: string;
    img: string;
    alt: string;
  }

  export default defineComponent({
    name: "Swiper",

    props: {
      list: {
        default: [],
        type: Array as PropType<BannerList[]>,
      },
    },
    setup(props) {
      const loading = ref(true);
      const swiper = ref<null | Swiper>(null);

      function initSwiper() {
        swiper.value = new Swiper(".banner-swiper-container", {
          autoplay: true,
          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          loop: true, // 循环模式选项
          on: {
            observerUpdate: function () {
              this.slideTo(0);
            },
          },
          // 如果需要分页器

          pagination: {
            el: ".swiper-pagination",
            bulletClass: "my-bullet",
            bulletActiveClass: "my-bullet-active",
          },
        });
      }

      //  解决页面被重新激活后轮播图失效
      watch(
              () => {
                return props.list;
              },
              () => {
                loading.value = false;
                nextTick(() => {
                  if (swiper.value) {
                    swiper.value.update();
                  }
                });
              }
      );

      onActivated((e: any) => {
        nextTick(() => {
          //  添加定时器，防止轮播不能滚动
          const timer = setTimeout(() => {
            initSwiper();
            clearTimeout(timer);
          }, 1000);
        });
      });
      onDeactivated(() => {
        if (swiper.value) {
          // swiper.value.destroy(true,true);
        }
      });

      return {
        loading,
      };
    },
  });
