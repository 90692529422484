<template>
    <keep-alive v-if="list"> <userBanner :list="list"></userBanner></keep-alive>

    <main>
        <ul class="coupon-tab">
            <li  :class="{avtive:num===0}" @click="getCouponList1()">待使用</li>
            <li :class="{avtive:num===1}" @click="getCouponList2()">已使用</li>
            <li  :class="{avtive:num===2}" @click="getCouponList3()">已过期</li>
        </ul>
        <div v-if="isShowNoCoupon">
            <div style="text-align: center;margin-top: 100px" >
                <img style="width:150px" src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/ff28d0d8e880ce543f328a08ace5928d.png" alt="">
            </div>
            <p style="text-align: center;margin: 20px 0;font-size: 16px">暂无优惠券</p>
        </div>

        <ul class="use-coupon">
            <li :class="[num===0?'normal':'lose']"
                v-for="(item,index) in couponList " :key="index">
                <div class="ly_user_couponPrice">
                    <p class="ly_user_couponPrice-S">￥<span>{{item.amount}}元</span></p>
                    <p style="font-size: 12px">{{item.createTime.substr(0,10)}} - {{item.expiredTime.substr(0,10)}}</p>
                </div>
                <div class="ly_user_couponUse">
                    <span v-if="item.couponStatus ===1">已使用</span>
                    <span v-else-if="item.beExpired ===1">已过期</span>
                    <span v-else-if="item.toBeExpired ===1">即将到期</span>
                    <span class="ly_user_couponB">
            <span v-if="num===0 && item.gift===1" @click="giveCoupon(item.id)">赠送
              <img src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/7d35eeddfe6663058690a20eb8a7eea3.png" alt="">
            </span>
          </span>
                </div>
            </li>
        </ul>
        <!--        弹窗-->
        <div class="modal1" id="modal1" v-if="showModal">
            <div class="modal-backdrop"></div>
            <div class="modal-body">
                <div class="modal-body-title">请选择您的好友或搜索<span style="color: #df0024">添加好友</span></div>
                <div class="like-user-search" >
                    <input type="text" placeholder="请输入好友昵称或手机号" v-model="searchText">
                    <span @click="searchUser()">搜索</span>
                </div>
                <form id="couponGiveForm">
                    <div  v-for="(item,index) in followList " :key="index">
                        <label>
                            <input type="radio" name="userId" :value="item.id" @click="getFollow(item.id)">
                            <img :src="item.userImg" :alt="item.nickName">
                            <span style="    width: 120px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: inline-block;">{{item.nickName}}</span>
                        </label>
                        <span v-if="item.isFollow===0" @click="focusFriends(item.id,index)">关注</span>
                        <span v-else-if="item.isFollow===1" @click="noFocusFriends(item.id,index)" style="background: #999;border: 1px solid #999">取关</span>
                    </div>

                </form>
                <div class="coupon-button">
                    <span class="coupon_btn" @click="DocouponGive()">赠送</span>
                    <span class="coupon_btn" style="background: #999" @click="closeModal()">取消</span>
                </div>
            </div>
        </div>
        <loading :options="loadOption" v-if="loading"></loading>
    </main>
</template>

<script lang="ts">
    import Loading from "@/components/UI/Loading/src/Loading.vue";
    import {defineComponent, reactive, ref, watch, inject} from "vue";
    import axios from "@/api/axios";
    import Toast from "@/components/UI/Toast";
    import qs from "qs";
    import userBanner from "@/components/common/UserBanner.vue";

    export default defineComponent({
        components:{
            Loading,
            userBanner
        },
        name: "Coupon",
        data() {
            return {
                list: [],
                num:0,
                //用户id
                userInfo: {},
                couponList: [],
                showModal: false,
                page: 1,
                // 好友列表
                followList: [],
                // 选中好友
                followGet: '',
                //送券ID
                couponId: '',
                //暂无优惠券
                isShowNoCoupon:false,
                isGetList:true,
                searchText:'',
                loading:false,
                loadOption:{
                    text: "加载中...",
                    color: "#df0024",
                    textColor: "#df0024",
                }
            }
        },
        mounted(): void {
            this.userInfo = inject("userInfo") as any;
            this.getBanners();
            //默认查询待使用
            this.getCouponList1();
            // 监听页面滚动
            window.addEventListener('scroll', this.pageScroll, true)
        },
        methods: {
            getCouponList1(){
                this.loading=true;
                this.couponList=[]
                this.isShowNoCoupon=false
                axios.post("/M/couponlog/list?type=1", this.page)
                    .then((res) => {
                        this.loading=false;
                        if (res.data.success) {
                            this.couponList = res.data.data;
                            if (this.couponList.length == 0) {
                                this.isShowNoCoupon=true
                            }
                        } else {
                            this.isShowNoCoupon=false
                        }
                    })
                    .catch((err) => {
                        this.loading=false;
                        Toast({
                            type: "error",
                            title: '获取优惠券失败，请联系客服',
                        });
                    });
                this.num=0
            },
            getCouponList2(){
                this.loading=true;
                this.couponList=[];
                this.isShowNoCoupon=false
                axios.post("/M/couponlog/list?type=2", this.page)
                    .then((res) => {
                        this.loading=false;
                        if (res.data.success) {
                            this.couponList = res.data.data;
                            if (this.couponList.length == 0) {
                                this.isShowNoCoupon=true
                            }
                        } else {
                            this.isShowNoCoupon=false
                        }
                    })
                    .catch((err) => {
                        this.loading=false;
                        Toast({
                            type: "error",
                            title: '获取优惠券失败，请联系客服',
                        });
                    });
                this.num=1

            },
            getCouponList3(){
                this.loading=true;
                this.couponList=[];
                this.isShowNoCoupon=false
                axios.post("/M/couponlog/list?type=3", this.page)
                    .then((res) => {

                        this.loading=false;
                        if (res.data.success) {
                            this.couponList = res.data.data;
                            if (this.couponList.length == 0) {
                                this.isShowNoCoupon=true
                            }
                        } else {
                            this.isShowNoCoupon=false
                        }
                    })
                    .catch((err) => {
                        console.log("err");
                        this.loading=false;
                        Toast({
                            type: "error",
                            title: '获取优惠券失败，请联系客服',
                        });
                    });
                this.num=2
            },
            searchUser() {
                this.loading=true;
                if(this.searchText.trim()===''||this.searchText.trim()==='1'){
                    Toast({
                        type: "error",
                        title: '请输入正确的昵称或手机号',
                    });
                    this.loading=false;
                    return false
                }
                axios.post("/M/couponlog/followList?name="+this.searchText+'&type=1')
                    .then((res) => {
                        if (res.data.success) {
                            this.loading=false;
                            console.log(res.data)
                            this.followList = res.data.data;
                            if(this.followList.length===0){
                                Toast({
                                    type: "error",
                                    title: '抱歉，无法搜索对应的结果',
                                });
                            }
                        } else {
                            this.loading=false;
                            Toast({
                                type: "error",
                                title: '系统问题，请联系管理员',
                            });
                        }
                    }).catch((err)=>{
                    Toast({
                        type: "error",
                        title: err.message,
                    });
                    }

                )
            },
            focusFriends(uid: any,index: any){
                axios.post('M/BBSServer/Follow', qs.stringify({
                        userid: uid,
                    }),
                    {
                        params: {
                            validate: true,
                        },
                    }).then((res) => {
                    console.log(res)
                        if (res.data.success) {
                            console.log(res.data);
                            Toast({
                                type: "success",
                                title: '关注成功',
                            });
                            this.loading=false;
                            this.followList[index].isFollow=1
                        } else {
                            this.loading=false;
                            Toast({
                                type: "error",
                                title: '操作失败，请联系管理员',
                            });
                        }
                    }).catch((err)=>{
                        Toast({
                            type: "error",
                            title: '操作失败，请联系管理员',
                        });
                    }

                )
            },
            noFocusFriends(uid: any,index: any){
                axios.post('M/BBSServer/CancelFollow', qs.stringify({
                        userid: uid,
                    }),
                    {
                        params: {
                            validate: true,
                        },
                    }).then((res) => {
                    console.log(res)
                    if (res.data.success) {
                        console.log(res.data);
                        Toast({
                            type: "success",
                            title: '取消关注成功',
                        });
                        this.loading=false;
                        this.followList[index].isFollow=0
                    } else {
                        this.loading=false;
                        Toast({
                            type: "error",
                            title: '操作失败，请联系管理员',
                        });
                    }
                }).catch((err)=>{
                        Toast({
                            type: "error",
                            title: '操作失败，请联系管理员',
                        });
                    }

                )
            },
            // 下拉加载
            pageScroll(){
                // 获取滚动的距离
                let scrollTop=document.documentElement.scrollTop;
                // 获取滚动的高度（获取整个html的高度）
                let scrollHeight=document.documentElement.scrollHeight;
                // 获取屏幕(浏览器)高度
                let clienHeight=document.documentElement.clientHeight;
                // console.log(scrollTop)
                // 滚动的距离 + 屏幕高度 - 内容高度 >= 0 表示滚动到底部了      (下拉加载判断条件)
                if(scrollTop==0){
                    return false
                }
                if( this.isGetList){
                    if(scrollTop+clienHeight-scrollHeight >=0){
                        //下拉底部则请求下一页数据填充，若无数据则不请求
                        this.loading=true
                        this.page+=1;
                        //滚动至底部后请求数据
                        axios.post("/M/couponlog/list?page=" +this.page)
                            .then((res) => {
                                this.loading=false
                                if (res.data.success) {
                                    this.couponList= [... this.couponList,...res.data.data];
                                    if (res.data.data.length == 0) {
                                        Toast({
                                            type: "warning",
                                            title: '暂无更多优惠券',
                                        });
                                        this.isGetList=false
                                    }else {
                                        // Toast({
                                        //     type: "default",
                                        //     title: '加载中...',
                                        // });

                                    }

                                } else {

                                    Toast({
                                        type: "warning",
                                        title: '系统错误，请联系管理员',
                                    });
                                }
                            })
                            .catch((err) => {
                                this.loading=false
                                Toast({
                                    type: "warning",
                                    title: err.message,
                                });
                            });

                    }
                }

            },
            //选定好友
            getFollow(id: any) {
                this.followGet = id
                // console.log(this.couponId, this.followGet)
            },

            closeModal() {
                this.showModal = false;
                this.searchText=''
            },
            //确定送出
            DocouponGive() {
                console.log(this.followGet)
                if(this.followGet){
                    axios.post("/M/couponlog/couponGive?uid=" + this.followGet + '&couponId=' + this.couponId)
                        .then((res) => {
                            if (res.data.success) {
                                Toast({
                                    title: res.data.msg,
                                    type: "success",
                                });
                                // 送完更新优惠券
                                this.getCouponList1()
                            } else {
                                Toast({
                                    title: res.data.msg,
                                    type: "warning",
                                });
                            }
                            this.closeModal()
                        })
                        .catch((err) => {
                            Toast({
                                title: err.message,
                                type: "warning",
                            });
                        });
                }else {
                    Toast({
                        type: "error",
                        title: '请先勾选要赠送的人',
                    });


                    this.closeModal()
                }
            },
            //点击赠送弹窗获取好友
            giveCoupon(id: any) {
                this.loading=true;
                this.couponId = id;
                axios.post("/M/couponlog/followList")
                    .then((res) => {
                        if (res.data.success) {
                            this.loading=false;
                            this.followList = res.data.data;
                          if( Array.isArray(this.followList)) {
                              // if(this.followList.length===0){
                              //     Modal({
                              //         title: "提示",
                              //         content: "抱歉，您暂时还没有好友，是否添加好友",
                              //         onConfirm: () => {
                              //             this.$router.push('/Personal')
                              //         }
                              //     })
                              //     return false
                              // }
                              this.showModal = true
                          }

                        } else {
                            Toast({
                                type: "error",
                                title: '系统错误，请联系管理员',
                            });
                        }

                    })
                    .catch((err) => {
                        Toast({
                            type: "error",
                            title: '系统错误，请联系管理员',
                        });
                    });
            },
            getBanners(){
                this.list=[];
                axios.get("/M/User").then((res): void => {
                    this.list = res.data.obj.orderBanners;
                })
            }

        },
        beforeUnmount () {
            // 移除监听
            window.removeEventListener('scroll', this.pageScroll, true)
        },
    });
</script>

<style lang="scss" scoped>
    .coupon-tab{
        display: flex;
        justify-content: space-around;
        font-size: 14px;
        padding: 15px 0;
    }
    .coupon-tab li{
        width: 20%;
        text-align: center;
        padding: 0 0 10px;
    }
    .avtive{
        color: #df0024;
        border-bottom: 2px solid #df0024;
    }
    .like-user-search span{
        display: inline-block;
        width: 18%;
        font-size:14px;
        text-align: center;
        height: 30px;
        background: #df0024;
        padding: 0 10px;
        line-height: 30px;
        border-radius: 14px;
        position: relative;
        color: white;
        bottom: 1px;
        margin-left: 1%;
        right: 0;
        cursor: pointer;
    }
    .like-user-search{
        padding: 5px;
    }
    .like-user-search input{
        width: 80%;
        height:30px;
        background-color: #ffffff;
        border: solid 0.02667rem #dddddd;
        padding: 0 10px;
        border-radius: 14px;
        position: relative;
    }
    .modal-close {
        position: absolute;
        width: 20px;
        height: 20px;
        right: 0;
        top: 0;
        border-radius: 50%;
        border: 0;
    }
        .modal-body-title{
            text-align: center;
            font-size: 15px;
          position: relative;
            bottom: 10px;
        }
    #couponGiveForm  label input, #couponGiveForm  label span {
        position: relative;
        margin: 0 15px;
        bottom: 10px;
    }

    #couponGiveForm>div{
        border-bottom: 1px solid #eee;
        padding: 0.1rem 0;
    }
    #couponGiveForm>div>span {
        float: right;
        padding: 4px 6px;
        border: 1px solid red;
        cursor: pointer;
        background: #df0024;
        color: white;
        position: relative;
        top: 5px;
        margin-right: 10px;
        border-radius: 5px;
    }
    #couponGiveForm  label {
        cursor: pointer;
        padding: 10px 0;

        overflow: hidden;
    }
    #couponGiveForm  label img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }
    .modal-backdrop {
        /* 当打开模态框时，用半透明的蒙层遮挡网页剩余内容 */
        position: fixed;
        z-index: 99;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);

    }



    .modal-rushMessage {
        font-size: 16px;
        text-align: center;
        color: white;
        height: 130px;
        padding-top: 25px;
        margin: 0 auto;
        width: 80%;
    }

    .coupon-button {
        display: flex;
        width: 50%;
        margin: 0 auto;
        justify-content: space-around;
    }

    #couponGiveForm {
        height: 280px;
        overflow: auto;
    }

    .coupon_btn {
        color: #fff;
        font-size: 14px;
        margin-top: 7px;
        background: #e00025;
        padding: 4px 15px;
        border-radius: 6px;
    }

    .modal-body {
        background: white;
        border-radius: 8px;
        z-index: 99;
        width: 320px;
        height: 400px;
        position: fixed;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        padding: 20px 5px;
        font-size: 14px;
    }

    .modal-body ul {
        text-align: left;
        font-size: 12px;
        height: 150px;
        overflow-y: auto;
    }

    .modal-body ul li {
        margin: 8px auto;
        overflow: hidden;
        white-space: nowrap;
        width: 85%;
        text-overflow: ellipsis;
    }

    .modal-body ul li span {
        color: red;
    }

    main {
        padding-bottom: 2.933rem;
    }

    .use-coupon {
        width: 100%;
        /*margin: 0.15rem 0;*/
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 0 10px;

    }

    .ly_user_couponPrice-S span {
        font-size: 32px;
    }

    .ly_user_couponB {
        float: right;
    }

    .ly_user_couponB img {
        position: relative;
        left: 4px;
        top: 2px;
    }

    .ly_user_couponPrice-S {
        margin: 40px 0 10px;
    }

    .ly_user_couponPrice {
        color: white;
        text-align: center;
        line-height: 24px;
    }

    .ly_user_couponUse {
        position: absolute;
        bottom: 10px;
        width: 90%;
    }

    .use-coupon li {
        background-repeat: no-repeat;
        background-size: cover;
        /* -webkit-background-size: cover; */
        position: relative;
        -o-background-size: cover;
        width: 170px;
        height: 180px;
        padding: 0.3rem 0.2rem;
        margin: 4px 0;
        font-size: 14px;
    }

    .normal {
        background-image: url('https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/3da34edc377ee0e80fbe20083c6ed242.png');
    }

    .lose {
        background-image: url('https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/c530d3d2f08b449a7f75444e3ef5dd6a.png');
    }
</style>
